<template>
	<el-dialog class="dialog" title="编辑" :visible.sync="visiable" width="80%">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :inline="true" style="display: flex;" :model="formData" label-width="120px" size="mini">
				<div class="card" style="display: flex;flex-direction: column;flex: 1;border-right: 3px solid #F5F5F5">
					<div class="card" style="flex:1;">
						<span class="card-title">采集站信息</span>
						<div class="card-item">
							<el-form-item label="采集站名：">
								<el-input v-model="formData.name"></el-input>
							</el-form-item>
							<el-form-item label="采集站编码：">
								<el-input v-model="formData.no" type="number"></el-input>
							</el-form-item>
							<el-form-item label="企业名：">
								<el-input v-model="formData.ent_name" disabled></el-input>
							</el-form-item>
							<el-form-item label="采样长度：">
								<el-input v-model="formData.len" type="number"></el-input>
							</el-form-item>
							<el-form-item label="数据总包数：">
								<el-input v-model="formData.pack" type="number"></el-input>
							</el-form-item>
							<el-form-item label="采样率：">
								<el-input v-model="formData.rate" type="number"></el-input>
							</el-form-item>
						</div>
					</div>
					<!-- <div class="card" style="flex:1;border-top: 3px solid #F5F5F5">
						<span class="card-title">采集站策略</span>
						<div class="card-item">
							<el-form-item label="波形：">
								<el-input v-model="formData.name"></el-input>
							</el-form-item>
							<el-form-item label="采样值：">
								<el-input v-model="formData.name"></el-input>
							</el-form-item>
						</div>
					</div> -->
				</div>
				<!-- <div class="card" style="flex:1;">
					<span class="card-title">检测设备</span>
					<div class="card-item">
						<el-form-item label="采集站类型：">
							<el-input v-model="formData.name"></el-input>
						</el-form-item>
					</div>
				</div> -->
			</el-form>
		</div>
		<div style="margin-top: 20px;">
			<div class="card"
				style="display: flex;flex-direction: column;flex: 1;padding: 0 15px 20px;border-right: 3px solid #F5F5F5">
				<span class="card-title" style="padding-left: 0;">通道配置</span>
				<el-tabs type="border-card">
					<el-tab-pane label="振动">
						<el-table ref="usertable" :data="tablesData.vib" border height="350"
							:header-cell-style="{'background-color':'#f9fafc'}"
							:cell-style="{'height':'40px','padding':'0'}">
							<el-table-column prop="no" label="传感器编号" width="300">
							</el-table-column>
							<el-table-column prop="set_unit_meas_id" label="关联测点">
								<template slot-scope="scope">
									<el-select size="medium" clearable v-model="scope.row.set_unit_meas_id"
										@change="selectChange($event,'vib',scope.row.no)" style="width: 100%;">
										<el-option v-for="item in meas.vib" :key="item.set_unit_meas_id"
											:label="item.path" :value="item.set_unit_meas_id" :disabled="item.disabled">
										</el-option>
									</el-select>
								</template>
							</el-table-column>
						</el-table>
					</el-tab-pane>
					<el-tab-pane label="转速">
						<el-table ref="usertable" :data="tablesData.rpm" border height="200"
							:header-cell-style="{'background-color':'#f9fafc'}"
							:cell-style="{'height':'40px','padding':'0'}">
							<el-table-column prop="no" label="传感器编号" width="300">
							</el-table-column>
							<el-table-column prop="set_unit_meas_id" label="关联测点">
								<template slot-scope="scope">
									<el-select size="medium" clearable v-model="scope.row.set_unit_meas_id"
										@change="selectChange($event,'rpm',scope.row.no)" style="width: 100%;">
										<el-option v-for="item in meas.rpm" :key="item.set_unit_meas_id"
											:label="item.path" :value="item.set_unit_meas_id" :disabled="item.disabled">
										</el-option>
									</el-select>
								</template>
							</el-table-column>
						</el-table>
					</el-tab-pane>
					<el-tab-pane label="温度">
						<el-table ref="usertable" :data="tablesData.temp" border height="200"
							:header-cell-style="{'background-color':'#f9fafc'}"
							:cell-style="{'height':'40px','padding':'0'}">
							<el-table-column prop="no" label="传感器编号" width="300">
							</el-table-column>
							<el-table-column prop="set_unit_meas_id" label="关联测点">
								<template slot-scope="scope">
									<el-select size="medium" clearable v-model="scope.row.set_unit_meas_id"
										@change="selectChange($event,'temp',scope.row.no)" style="width: 100%;">
										<el-option v-for="item in meas.temp" :key="item.set_unit_meas_id"
											:label="item.path" :value="item.set_unit_meas_id" :disabled="item.disabled">
										</el-option>
									</el-select>
								</template>
							</el-table-column>
						</el-table>
					</el-tab-pane>
					<el-tab-pane label="油液">
						<el-table ref="usertable" :data="tablesData.oil" border height="200"
							:header-cell-style="{'background-color':'#f9fafc'}"
							:cell-style="{'height':'40px','padding':'0'}">
							<el-table-column prop="no" label="传感器编号" width="300">
							</el-table-column>
							<el-table-column prop="set_unit_meas_id" label="关联测点">
								<template slot-scope="scope">
									<el-select size="medium" clearable v-model="scope.row.set_unit_meas_id"
										@change="selectChange($event,'oil',scope.row.no)" style="width: 100%;">
										<el-option v-for="item in meas.oil" :key="item.set_unit_meas_id"
											:label="item.path" :value="item.set_unit_meas_id" :disabled="item.disabled">
										</el-option>
									</el-select>
								</template>
							</el-table-column>
						</el-table>
					</el-tab-pane>
				</el-tabs>

			</div>
		</div>

		<div class="dialog-footer">
			<el-button size="medium" @click="close()">关闭</el-button>
			<el-button type="primary" size="medium" @click="submit()">提交</el-button>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import smCard from "@c/smCard.vue"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		components: {
			smCard,
		},
		data() {
			return {
				loading: false,
				formData: {},
				tablesData: {
					vib: [],
					temp: [],
					rpm: [],
					oil: []
				},
				meas: {
					vib: [],
					temp: [],
					rpm: [],
					oil: []
				},
				selectedMeas: {
					vib: [],
					temp: [],
					rpm: [],
					oil: []
				},

			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.get();
			},
			initSelectInput(val, type, no) {
				var flag = true;
				for (var item of this.selectedMeas[type]) {
					if (item.no == no) {
						item.val = val;
						flag = false;
						break;
					}
				}
				if (flag) {
					this.selectedMeas[type].push({
						no,
						val
					});
				}
				for (var item of this.meas[type]) {
					item.disabled = false;
					for (var selectedItem of this.selectedMeas[type]) {
						if (item.set_unit_meas_id == selectedItem.val) {
							item.disabled = true;
						}
					}
				}
			},
			selectChange(val, type, no) {
				this.initSelectInput(val, type, no);
			},
			get() {
				this.$get("/backend-api/eqp/sta/diff", {
					sta_id: this.dialogObj.row.id
				}).then((res) => {
					if (res.code == 1000) {
						this.tablesData = res.data.sen;
						this.meas = res.data.meas;
						this.formData = res.data.sta;
						for (var key in this.tablesData) {
							for (var item of this.tablesData[key]) {
								this.initSelectInput(item.set_unit_meas_id, key, item.no);
							}
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			submit() {
				// console.log(JSON.stringify({
				// 	sta: this.formData,
				// 	sen: this.tablesData
				// }));
				this.loading = true;
				this.$put("/backend-api/eqp/sta/update", {
					sta: JSON.stringify(this.formData),
					sen: JSON.stringify(this.tablesData)
				}).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						this.close();
						this.$message.success(res.msg);
						//刷新表格
						this.$parent.list();

					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dlalog-body {
		position: relative;
	}

	.dialog-footer {
		padding: 10px;
		margin-top: 20px;
		background-color: #ffffff;
		text-align: right
	}

	.card {
		background-color: #ffffff;
	}

	.card-title {
		font-weight: bold;
		padding: 10px 15px;
		display: inline-block;
	}

	.card-item {
		display: flex;

		.el-form-item {
			flex: 1;
			display: flex;

			::v-deep .el-form-item__content {
				flex: 1;
			}
		}
	}

	/* 	.card {
		::v-deep .el-table .cell {
			overflow: inherit;
		}
	} */

	::v-deep .el-dialog__body {

		background: #F5F5F5;
	}
	
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
	}
	input[type='‘number’'] {
		-moz-appearance: textfield !important;
	}
</style>
