<template>
	<el-dialog title="新建" :visible.sync="visiable" width="30%">
		<el-form ref="form" :model="formData" label-width="120px">
			<el-row>
				<el-col :span="10">
					<el-form-item label="采集站名"><el-input v-model="formData.name"></el-input></el-form-item>
				</el-col>
				<el-col :span="10">
					<el-form-item label="采集编号"><el-input v-model="formData.no" type="number"></el-input></el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="8">
					<el-form-item label="采样长度"><el-input v-model="formData.len" type="number"></el-input></el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="数据总包数"><el-input v-model="formData.pack" type="number"></el-input></el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="采样率"><el-input v-model="formData.rate" type="number"></el-input></el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="24">
					<el-form-item label="企业名">
						<el-select v-model="formData.ent_id" clearable placeholder="请选择" style="width: 100%;">
							<el-option v-for="item in ents" :key="item.id" :label="item.title" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="close()">关闭</el-button>
			<el-button type="primary" @click="insert()">确定</el-button>
		</div>
	</el-dialog>
</template>
<script>
import { dialogMixin } from '@m/dialogMixin';
import configApi from '@config/configApi';
export default {
	mixins: [dialogMixin],
	data() {
		return {
			formData: {},
			ents: []
		};
	},
	props: {},
	watch: {},
	methods: {
		//打开弹窗
		open() {
			this.formData = {};
			this.get();
		},
		get() {
			this.$get('/backend-api/eqp/sta/toSave', null).then(res => {
				if (res.code == 1000) {
					this.ents = res.data.ents;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		insert() {
			this.$post('/backend-api/eqp/sta/save', this.formData).then(res => {
				if (res.code == 1000) {
					this.close();
					this.$message.success(res.msg);
					//刷新表格
					this.$parent.list();
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//关闭弹窗
		close() {
			this.visiable = false;
		}
	}
};
</script>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}
input[type='‘number’'] {
	-moz-appearance: textfield !important;
}
</style>
